@import '~antd/dist/antd.css';

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-popover-placement-bottomRight .ant-popover-inner-content {
  padding: 0px;
}

.ant-popover-placement-bottom .ant-popover-inner-content {
  width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  box-sizing: border-box;
  height: 100%;
  /* Override semantic UI overriding the root font size. Annoying */
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

div.vis-tooltip {
  background-color: #ffffff !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: 0px 10px 15px -3px rgba(155, 160, 166, 0.1),
    0px 4px 6px -2px rgba(155, 160, 166, 0.06) !important;
  border-radius: 4px !important;
}

/* More semantic overrides */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
}
